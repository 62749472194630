
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappsForm from "../../components/webapps/form"

const ENTITYID="nnadminassetman"

const searchFields = [
	{"label":"Name", "dbfield": "nnadminassetman_name", "type": "text", "filtertype": "textbox"},
	{"label":"Type", "dbfield": "nnadminassetmantype_name", "type": "text", "filtertype": "textbox"},
	{"label":"Image", "dbfield": "nnadminassetman_url", "type": "image", "filtertype": "none"},
	{"label":"Notes", "dbfield": "nnadminassetman_notes", "type": "text", "filtertype": "textbox"},
	{"label":"Active", "dbfield": "nnadminassetman_active", "type": "checkbox", "filtertype": "checkbox"}
];

const formFields = [
	[
		{"label":"Name", "field": "nnadminassetman_name", "value": "", "input": "textbox", "mode": "required"},
		{"label":"Type", "field": "nnadminassetmantype_name", "value": "", "input": "picker", "mode": "required"},
		{"label":"Image", "field": "nnadminassetman_url", "value": "", "input": "image", "mode": "required", "filehost": "cdn.ngnw.ph", "filelocation":"images/asset/uploads", "uploadtype":"https"},
		{"label":"Notes", "field": "nnadminassetman_notes", "value": "", "input": "textarea", "mode": "normal"}
	]
];

const formTailFields = [
	[
		{"label":"Active", "field": "nnadminassetman_active", "value": "", "input": "checkbox", "mode": "normal"}
	]
];

const AssetimagesPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	return <Layout fullPath={location.pathname}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				pagetitle={pagetitle}
				mobilerowtitlefield={["nnadminassetman_name"]}
				searchFields={searchFields}
				editFields={formFields.concat(formTailFields)}
				newformFields={formFields}
				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default AssetimagesPage;
